const { REACT_APP_API_ENDPOINT } = process.env;

const BASE_URL = REACT_APP_API_ENDPOINT;
const API_BASE_URL = `${BASE_URL}/api/v1`;
const API_URL = "https://chatbot.ibism.com"
// const API_URL = "http://localhost:4343"

const AppUrl = {
  LOGIN: `${API_BASE_URL}/auth/login`,
  OPENAI: `${API_BASE_URL}/openAi`,
  FILES: `${API_URL}/api/`,
  LOGIN_URL: `${API_URL}/api/login`,
  SIGNUP_URL: `${API_URL}/api/register`,
  GET_FILE: `${API_URL}/api/get-data`,
  SAVE_FILE: `${API_URL}/api/save-data`,
  UPLOAD_FILE: `${API_URL}/api/upload-data`,
  CUSTOM_FETCH: `${API_URL}/api/custom-fetch`,
  GET_JSON: `${API_URL}/api/get-json`,
  HTML_DETAIL: `${API_URL}/api/get-html-details`,
  SEND_MAIL:`${API_URL}/api/send-mail`,
  HISTORY:`${API_URL}/api/history`,
  DASHBOARD:`${API_URL}/api/dashboard`,
  USERS:`${API_URL}/api/users`,
  UPLOAD: `${API_URL}/api/upload`
};

Object.freeze(AppUrl);
export default AppUrl;
